@font-face {
  font-family: 'Poppins';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: local('Poppins Regular'), local('Poppins-Regular'),
    url('../fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: local('Poppins Medium'), local('Poppins-Medium'),
    url('../fonts/Poppins-Medium.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-display: swap;
  font-style: normal;
  font-weight: 800;
  src: local('Poppins Bold'), local('Poppins-Bold'),
    url('../fonts/Poppins-Bold.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
    url('../fonts/Poppins-SemiBold.ttf');
}